import { deleteTokenOperation, setTokenOperation } from 'store/auth/operations';
import { getAuthToken } from 'store/auth/accessors';

export const getToken = (): string | null => {
    return getAuthToken();
};

export const removeToken = (): void => {
    deleteTokenOperation();
};

export const setToken = (token: string): void => {
    setTokenOperation(token);
};
