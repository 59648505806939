import Workouts from './components/Workouts';
import UserApi from './components/User';
import Subscriptions from './components/Subscriptions';
import Challenge75 from './components/Challenge75';

function ApiInstance() {
    const user = new UserApi();
    const subscriptions = new Subscriptions();
    const workouts = new Workouts();
    const challenge75 = new Challenge75();

    return {
        user,
        subscriptions,
        workouts,
        challenge75,
    };
}

export default ApiInstance();
