import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { memo, useEffect } from 'react';

import { AVAILABLE_CURRENCY, NO_CENTS_CURRENCIES } from 'constants/subscriptions';
import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import { sendAnalyticSpecialOfferScreenLoad } from 'services/analytics';

import { useSpecialOfferModal } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';
import DiscountOffer from 'components/DiscountOffer';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import { SpecialOfferModalProps } from './types';

const SpecialOfferModal = ({
    externalId,
    onSubmitDiscount,
    onCancelSubscription,
    hideBackBtn,
}: SpecialOfferModalProps) => {
    const { t } = useTranslation();
    const { candidatePriceData, discountPriceData } = useSpecialOfferModal(externalId);

    useEffect(() => {
        sendAnalyticSpecialOfferScreenLoad();
    }, []);

    if (!candidatePriceData || !discountPriceData) {
        return null;
    }

    const oldPrice = NO_CENTS_CURRENCIES.includes(candidatePriceData.currency)
        ? String(candidatePriceData.integer)
        : `${candidatePriceData.integer}.${candidatePriceData.cents}`;

    return (
        <Modal
            isOpen
            fullscreen
            style={{
                content: MODAL_CONTENT_FULL_SCREEN_STYLES,
            }}
        >
            <>
                <CancelModalNavBar isFirstModal={hideBackBtn} />
                <S.ModalContainer data-locator="discountOfferContainer">
                    <Text
                        text={t('subscription.cancellation.specialOffer.withDynamicPercent.title', { percent: 50 })}
                        type="h4"
                        medium
                        center
                        mb={12}
                    />
                    <Text
                        text={t('subscription.cancellation.specialOffer.withDynamicPercent.subtitle', { percent: 50 })}
                        type="large-text"
                        center
                        mb={24}
                    />
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="text-secondary"
                        badgeText="subscription.cancellation.specialOffer.firstBadge"
                        price={oldPrice}
                        period={candidatePriceData.period}
                        currencySign={AVAILABLE_CURRENCY[candidatePriceData.currency]}
                    />
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="critical-default"
                        badgeText="subscription.cancellation.specialOffer.withDynamicPercent.secondBadge"
                        badgePercent={50}
                        oldPrice={oldPrice}
                        price={
                            NO_CENTS_CURRENCIES.includes(discountPriceData.currency)
                                ? String(discountPriceData.integer)
                                : `${discountPriceData.integer}.${discountPriceData.cents}`
                        }
                        period={candidatePriceData.period}
                        isNewOffer
                        currencySign={AVAILABLE_CURRENCY[discountPriceData.currency]}
                        onSubmitDiscount={onSubmitDiscount}
                    />
                    <OutlinedButton
                        dataLocator="discountOfferCancelSubscriptionButton"
                        text={t('subscription.text.cancel')}
                        onClick={onCancelSubscription}
                    />
                </S.ModalContainer>
            </>
        </Modal>
    );
};

export default memo(SpecialOfferModal);
