import { Image, Text, Box } from 'wikr-core-components';
import Skeleton from 'react-loading-skeleton';
import { Trans, useTranslation } from 'react-i18next';
import React, { RefObject } from 'react';
import QRCode from 'qrcode.react';
import { useInView } from '@react-spring/web';

import { selectCurrentUser } from 'store/user/selectors';

import { MOBILE_DOWNLOAD_STEPS, DESKTOP_DOWNLOAD_STEPS } from './constants';

import { useSelector } from 'hooks';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';

import * as S from './styled';

import halfPhoneAndroid from './img/halfPhoneAndroid.webp';
import halfPhone from './img/halfPhone.webp';
import downloadPhone from './img/downloadPhone.webp';
import { ReactComponent as Arrow } from './img/arrow.svg';

import GreenTipContainer from '../GreenTipContainer';

function DownloadBlock({
    isLoading,
    deepLinkUrl,
    isBigScreen,
    isAndroid,
    handleDownloadApp,
    blockRef,
}: {
    isLoading: boolean;
    deepLinkUrl: string;
    isBigScreen: boolean;
    isAndroid: RegExpMatchArray | null;
    handleDownloadApp: () => void;
    blockRef?: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null;
}) {
    const { email } = useSelector(selectCurrentUser);

    const { t } = useTranslation();

    const downloadSteps = isBigScreen ? DESKTOP_DOWNLOAD_STEPS : MOBILE_DOWNLOAD_STEPS;

    const [btnRef, inViewBtn] = useInView();

    const halfPhoneImg = isAndroid ? halfPhoneAndroid : halfPhone;

    return (
        <>
            <S.DownloadBlock ref={blockRef}>
                <S.TitleStepsWrap>
                    <S.InstructionsTitle mb={40} medium text={t('main.downloadBlock.title')} />
                    {!isBigScreen && (
                        <>
                            <Image alt="halfPhone" src={halfPhoneImg} center maxWidth={193} mb={20} isForceVisible />
                            <div ref={btnRef}>
                                <SecondaryDefaultButton
                                    dataLocator="deeplinkBtn"
                                    withPulsating
                                    text={t('main.button')}
                                    onClick={handleDownloadApp}
                                    mb={54}
                                    restProps={{ 'data-deeplink': deepLinkUrl }}
                                />
                            </div>
                        </>
                    )}
                    <S.InstructionsWrap>
                        {downloadSteps.map((step, index) => (
                            <S.OneFeature key={index}>
                                <S.StepCircle>
                                    <Text text={`${index + 1}`} color="on-primary" type="large-text" bold />
                                </S.StepCircle>
                                <S.StepText>
                                    <Trans i18nKey={step} components={{ bold: <b /> }} values={{ email }} />
                                </S.StepText>
                            </S.OneFeature>
                        ))}
                        <Text text={t('main.appLinksBlock.stroke')} />
                    </S.InstructionsWrap>
                </S.TitleStepsWrap>
                {isBigScreen && (
                    <S.ImgWithTextWrap>
                        <S.ImgWrap>
                            <Image isForceVisible src={downloadPhone} alt="downloadPhone" />
                            <S.ArrowWrap>
                                <Arrow />
                            </S.ArrowWrap>
                            <S.ScanQrCodeWrapper>
                                {isLoading ? (
                                    <Skeleton borderRadius={16} width={147} height={147} />
                                ) : (
                                    <Box
                                        paddingX={7}
                                        paddingTop={7}
                                        paddingBottom={4}
                                        backgroundColor="on-primary"
                                        borderRadius={16}
                                    >
                                        <QRCode size={147} value={deepLinkUrl} />
                                    </Box>
                                )}
                            </S.ScanQrCodeWrapper>
                        </S.ImgWrap>
                        <Text
                            color="text-secondary"
                            center
                            medium
                            type="small-text"
                            text={t('main.downloadBlock.imgCaption')}
                        />
                    </S.ImgWithTextWrap>
                )}
                <S.GreenTipBlockWrap>
                    <GreenTipContainer text="main.downloadBlock.greenBlock" />
                </S.GreenTipBlockWrap>
            </S.DownloadBlock>
            {!isBigScreen && !inViewBtn && (
                <S.ButtonFixedWrap>
                    <SecondaryDefaultButton
                        dataLocator="deeplinkBtn"
                        withPulsating
                        text={t('main.button')}
                        onClick={handleDownloadApp}
                        restProps={{
                            'data-deeplink': deepLinkUrl,
                        }}
                    />
                </S.ButtonFixedWrap>
            )}
        </>
    );
}

export default DownloadBlock;
