import ApiClient from 'services/ApiClient';

import { IChallengeResponse } from 'types/challenge75';

class Challenge75 {
    getChallenge75 = async <Response = IChallengeResponse>() => {
        return await ApiClient.get<Response>('user/challenge_75/');
    };
}

export default Challenge75;
