import { selectHasChallenge, selectHasFlatBelly, selectHasWorkouts } from 'store/user/selectors';

import { useSelector } from 'hooks';

import { getSideBarPrimaryLinks } from './getSideBarPrimaryLinks';

const usePrimaryLinks = () => {
    const isHideWorkouts = useSelector(selectHasWorkouts);

    const isHideChallenge = useSelector(selectHasChallenge);

    const isHideFlatBelly = useSelector(selectHasFlatBelly);

    const primarySideBarLinks = getSideBarPrimaryLinks({ isHideWorkouts, isHideChallenge, isHideFlatBelly });

    return {
        primarySideBarLinks,
        filteredPrimarySideBarLinks: primarySideBarLinks.filter(({ isShowItem = true }) => isShowItem),
    };
};

export default usePrimaryLinks;
