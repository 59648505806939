import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';

import config from 'config';

import { getToken, setToken } from 'api/utils/tokenManagement';

import sentry from 'services/Sentry/SentryInstance';

import { initApiClient } from 'welltech-web-api-client';

function ApiClient() {
    return initApiClient({
        apiUrl: config.WORKOUT_API_URL,
        apiVersion: config.API_VERSION,
        apiKey: config.WORKOUT_API_KEY,
        retries: 3,
        extendResponse: false,
        // this need to call workout api
        requestInterceptors: [
            (config) => {
                config.headers['project'] = 'fasteasy';
                config.headers['project-id'] = 'fasting';

                return config;
            },
        ],
        getAuthToken: () => getToken(),
        onUpdateToken: (token) => {
            setToken(token);
        },
        onRetry: (retryCount, error, requestConfig) => {
            sentry.logError(error as Error, SENTRY_AXIOS, ERROR_LEVELS.WARNING, { ...requestConfig });
        },
        onErrorHandler: ({ extras, status, message }) => {
            const level = Number(status) >= 500 ? ERROR_LEVELS.CRITICAL : ERROR_LEVELS.ERROR;

            sentry.logError(new Error(message), SENTRY_AXIOS, level, extras);
        },
    });
}

export default ApiClient();
