import { ComponentType, ComponentProps } from 'react';

import WaitingModalContainer from 'components/ModalContainers/WaitingModalContainer';
import SurveyThankYouModalContainer from 'components/ModalContainers/SurveyThankYouModalContainer';
import SurveyModalContainer from 'components/ModalContainers/SurveyModalContainer';
import SuccessFeedbackModalContainer from 'components/ModalContainers/SuccessFeedbackModalContainer';
import SpecialOfferModalContainer from 'components/ModalContainers/SpecialOfferModalContainer';
import SecondSubscriptionCancelModalContainer from 'components/ModalContainers/SecondSubscriptionCancelModalContainer/SecondSubscriptionCancelModalContainer';
import ReturnOfferModalContainer from 'components/ModalContainers/ReturnOfferModalContainer';
import ReturnOfferCRMModalContainer from 'components/ModalContainers/ReturnOfferCRMModalContainer';
import RestoringSubscriptionModalContainer from 'components/ModalContainers/RestoringSubscriptionModalContainer';
import ReminderOfferModalContainer from 'components/ModalContainers/ReminderOfferModalContainer';
import ProductProposeContainer from 'components/ModalContainers/ProductProposeContainer';
import PauseOfferModalContainer from 'components/ModalContainers/PauseOfferModalContainer';
import ModalWithLoaderContainer from 'components/ModalContainers/ModalWithLoaderContainer';
import LifeTimeAccessQuestionContainer from 'components/ModalContainers/LifeTimeAccessQuestionContainer';
import LifeTimeAccessFinalModalContainer from 'components/ModalContainers/LifeTimeAccessFinalModalContainer';
import GuidesOfferContainer from 'components/ModalContainers/GuidesOfferContainer';
import FreeMonthModalContainer from 'components/ModalContainers/FreeMonthModalContainer';
import ErrorFeedbackModalContainer from 'components/ModalContainers/ErrorFeedbackModalContainer';
import CheckOurAppsContainer from 'components/ModalContainers/CheckOurAppsContainer';
import ChallengeRulesModalContainer from 'components/ModalContainers/ChallengeRulesModalContainer';
import ChallengeMoreModalContainer from 'components/ModalContainers/ChallengeMoreModalContainer';
import CancellationReasonModalContainer from 'components/ModalContainers/CancellationReasonModalContainer';
import CaliforniaCancellationReasonModalContainer from 'components/ModalContainers/CaliforniaCancellationReasonModalContainer';
import AlertModalContainer from 'components/ModalContainers/AlertModalContainer';
import AdditionalQuestionContainer from 'components/ModalContainers/AdditionalQuestionContainer';

export enum ModalName {
    alertModal = 'alertModal',
    reasonModal = 'reasonModal',
    specialOfferModal = 'specialOfferModal',
    waitingModal = 'waitingModal',
    productPropose = 'productPropose',
    checkOurApps = 'checkOurApps',
    survey = 'survey',
    surveyThankYou = 'surveyThankYou',
    lifeTimeAccessQuestion = 'lifeTimeAccessQuestion',
    lifeTimeAccessFinal = 'lifeTimeAccessFinal',
    additionalQuestion = 'additionalQuestion',
    successFeedbackModal = 'successFeedbackModal',
    errorFeedbackModal = 'errorFeedbackModal',
    secondSubscriptionCancelModal = 'secondSubscriptionCancelModal',
    withLoaderModal = 'withLoaderModal',
    freeMonthOffer = 'freeMonthOffer',
    guidesOfferModal = 'guidesOfferModal',
    reminderOffer = 'reminderOffer',
    pauseModal = 'pauseModal',
    californiaReasonModal = 'californiaReasonModal',
    returnOfferModal = 'returnOfferModal',
    returnOfferCRMModal = 'returnOfferCRMModal',
    restoringSubscriptionModal = 'restoringSubscriptionModal',
    challengeRulesModal = 'challengeRulesModal',
    challengeMoreModal = 'challengeMoreModal',
}

export type ModalPropsType = {
    [ModalName.alertModal]: ComponentProps<typeof AlertModalContainer>;
    [ModalName.reasonModal]: ComponentProps<typeof CancellationReasonModalContainer>;
    [ModalName.specialOfferModal]: ComponentProps<typeof SpecialOfferModalContainer>;
    [ModalName.waitingModal]: ComponentProps<typeof WaitingModalContainer>;
    [ModalName.productPropose]: ComponentProps<typeof ProductProposeContainer>;
    [ModalName.checkOurApps]: ComponentProps<typeof CheckOurAppsContainer>;
    [ModalName.survey]: ComponentProps<typeof SurveyModalContainer>;
    [ModalName.surveyThankYou]: ComponentProps<typeof SurveyThankYouModalContainer>;
    [ModalName.lifeTimeAccessQuestion]: ComponentProps<typeof LifeTimeAccessQuestionContainer>;
    [ModalName.lifeTimeAccessFinal]: ComponentProps<typeof LifeTimeAccessFinalModalContainer>;
    [ModalName.additionalQuestion]: ComponentProps<typeof AdditionalQuestionContainer>;
    [ModalName.successFeedbackModal]: ComponentProps<typeof SuccessFeedbackModalContainer>;
    [ModalName.errorFeedbackModal]: ComponentProps<typeof ErrorFeedbackModalContainer>;
    [ModalName.secondSubscriptionCancelModal]: ComponentProps<typeof SecondSubscriptionCancelModalContainer>;
    [ModalName.withLoaderModal]: ComponentProps<typeof ModalWithLoaderContainer>;
    [ModalName.freeMonthOffer]: ComponentProps<typeof FreeMonthModalContainer>;
    [ModalName.guidesOfferModal]: ComponentProps<typeof GuidesOfferContainer>;
    [ModalName.reminderOffer]: ComponentProps<typeof ReminderOfferModalContainer>;
    [ModalName.pauseModal]: ComponentProps<typeof PauseOfferModalContainer>;
    [ModalName.californiaReasonModal]: ComponentProps<typeof CaliforniaCancellationReasonModalContainer>;
    [ModalName.returnOfferModal]: ComponentProps<typeof ReturnOfferModalContainer>;
    [ModalName.returnOfferCRMModal]: ComponentProps<typeof ReturnOfferCRMModalContainer>;
    [ModalName.restoringSubscriptionModal]: ComponentProps<typeof RestoringSubscriptionModalContainer>;
    [ModalName.challengeRulesModal]: ComponentProps<typeof ChallengeRulesModalContainer>;
    [ModalName.challengeMoreModal]: ComponentProps<typeof ChallengeMoreModalContainer>;
};

export type ModalsMapType = {
    [key in ModalName]: ComponentType<ModalPropsType[key]>;
};
