import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { getDeepLinkError, getDeepLinkRequest, getDeepLinkSuccess } from './actions';

import { GET_DEEP_LINK_REQUEST } from './actionTypes';

export function* getDeepLink({ payload: { onSuccess } }: ReturnType<typeof getDeepLinkRequest>) {
    try {
        const deepLink: { url: string } = yield call(api.user.deepLinks);

        yield put(getDeepLinkSuccess(deepLink.url));

        if (deepLink) {
            onSuccess && onSuccess();
        }
    } catch (error: any) {
        yield put(getDeepLinkError(error.error));
    }
}

export default function* watchDeepLink() {
    yield all([takeLatest(GET_DEEP_LINK_REQUEST, getDeepLink)]);
}
