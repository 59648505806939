import ApiClient from 'services/ApiClient';

import {
    DeepLinkData,
    SignInData,
    SignUpData,
    UserInfoResponse,
    UserUpdateData,
    ChangePasswordType,
    HashedEmailResp,
    UserWeightStatistic,
} from 'types/user/userApiInterface';
import { IBlockById, IBlocks, ICategories } from 'types/insights';

class UserApi {
    getUser = async <Response = UserInfoResponse>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/');
    };

    update = async <Data = UserUpdateData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.patch<Data, Response>('user/', payload);
    };

    setUserGuidesOffer = async <Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('user/offers', payload);
    };

    changePassword = async <Data = ChangePasswordType, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('user/password-change', payload);
    };

    signIn = async <Data = SignInData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('sign-in/', payload);
    };

    resetPassword = async <Data = SignInData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('user/reset-password', payload);
    };

    signUp = async <Data = SignUpData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('sign-up/', payload);
    };

    deepLinks = async <Response = DeepLinkData>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/auth-deeplink');
    };

    updateConsent = async <Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('user/', payload);
    };

    getUserBySignature = async <Response = UserInfoResponse>(
        params: Record<string, string>
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.get<Response>(`core/user/${params.uId}/${params.signature}`);
    };

    hashedEmail = async () => {
        return await ApiClient.get<HashedEmailResp>('front-hash');
    };

    getProducts = async ({ productCodes }: { productCodes?: string }) => {
        const productCodesQueryParam = productCodes ? `/?${productCodes}` : '';
        const url = `user/products${productCodesQueryParam}`;

        return await ApiClient.get<HashedEmailResp>(url);
    };
    // block_limit=9 task required
    getInsights = async (limit = 9) => {
        return await ApiClient.get<ICategories>(`insights/categories?block_limit=${limit}`);
    };

    getBlockByCategory = async (categoryId: string) => {
        return await ApiClient.get<IBlocks>(`insights/blocks?category=${categoryId}`);
    };

    getNextBlockByCategory = async (params: { categoryId: string; pageNumber: number }) => {
        return await ApiClient.get<IBlocks>(`insights/blocks?category=${params.categoryId}&page=${params.pageNumber}`);
    };

    getBlockPreview = async (blockId: number) => {
        return await ApiClient.get<IBlockById>(`insights/blocks/${blockId}`);
    };

    getWeightStatistic = async (from: string) => {
        return await ApiClient.get<{ items: UserWeightStatistic[] }>(`core/users/weights?from=${from}`);
    };

    deleteUser = async (userId: string) => {
        return await ApiClient.delete(`users/${userId}`, {});
    };
}

export default UserApi;
