import config from 'config';

import api from 'api';

import { HashedEmailResp } from 'types/user/userApiInterface';

export const initFrontChat = ({
    name,
    email,
    customFields,
    language,
}: {
    name: string | null;
    email: string | null;
    customFields?: any;
    language: string;
}) => {
    try {
        if (window.FrontChat) {
            shutdownFrontChat();
        }

        api.user.hashedEmail().then(({ data }) => {
            const initData = {
                chatId: config.FRONT_CHAT_ID,
                useDefaultLauncher: true,
                email,
                name,
                userHash: data,
                customFields: {
                    platform: 'web',
                    project: config.PROJECT_NAME,
                    language,
                    ...customFields,
                },
            };

            if (!window.FrontChat) {
                insertScript(initData);
            }
        });
    } catch (e) {
        console.error('chat error', e);
    }
};

export const insertScript = (initData: {
    userHash: string;
    chatId: string;
    customFields: any;
    name: string | null;
    useDefaultLauncher: boolean;
    email: string | null;
}) => {
    const d = document;
    const script = d.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
    d.body.appendChild(script);
    script.onload = () => {
        window.FrontChat && window.FrontChat('init', initData);
    };
};

export const shutdownFrontChat = () => {
    if (window.FrontChat) {
        window.FrontChat('shutdown');
        delete window.FrontChat;
    }
};
